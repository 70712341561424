/*
 * This file is part of the Energima Nettside 2021 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import useMount from 'hooks/mount';
import useInview from 'hooks/inview';
import Picture from 'components/Picture/Picture';
import useAnimation from './animation';

/**
 * This is the Person component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function Person({
  animate: animateProp,
  animationTiming,
  level,
  title,
  portrait,
  name,
  company,
  role,
  phone,
  email,
}) {
  const ref = useRef(null);
  const mount = useMount();
  const inview = useInview(ref, { threshold: 0.1 });
  const animate = useAnimation(ref, animateProp, animationTiming);

  useEffect(() => {
    if (inview) {
      animate();
    }
  }, [inview]);

  function renderPortrait() {
    if (!portrait) return null;

    return <Picture {...portrait} />;
  }

  const Header = `h${level}`;

  function getStyle() {
    if (!mount) return null;

    return {
      visibility: 'hidden',
    };
  }

  function renderTitle() {
    if (!title) return null;

    return <strong className="h4 title">{title}</strong>;
  }

  return (
    <div ref={ref} className="person" style={getStyle()}>
      {renderTitle()}
      <div className="image image-portrait">
        <div>{renderPortrait()}</div>
      </div>
      <div>
        <Header className="name">{name}</Header>
        {role ? <div className="role">{role}</div> : null}
        {company ? <div className="company">{company.name}</div> : null}
        {phone ? (
          <div className="phone">
            <a className="link" href={`tel:${phone}`}>
              {phone}
            </a>
          </div>
        ) : null}
        {email ? (
          <div className="email">
            <a className="link" href={`mailto:${email}`}>
              {email}
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Person.propTypes = {
  animate: PropTypes.bool,
  animationTiming: PropTypes.object.isRequired,
  level: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  title: PropTypes.string,
  portrait: PropTypes.object,
  name: PropTypes.string,
  company: PropTypes.shape({
    name: PropTypes.string,
  }),
  role: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Person.defaultProps = {
  title: null,
  animate: false,
  animationTiming: {},
  level: 2,
  portrait: null,
};

export default Person;
