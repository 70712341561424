import NoiseLines from './noiselines/noiselines';

class Wave {
  constructor(node, options = {}) {
    this.props = {
      interactive: false,
      animated: false,
      lineColor: '#fff',
      lineOpacity: 0.9,
      frequencyMin: 0.001,
      frequencyMax: 0.003,
      minAmplitude: 0.2,
      maxAmplitude: 0.4,
      timeScale: 0.2,
      numLines: 30,
      lineSegments: 200,
      noiseOffset: 0.015,
      enableGui: false,
      ...options,
    };

    this.node = node;

    /* prevent double decoration */
    if (this.node.decorator) {
      if (this.node.decorator instanceof Wave) {
        this.node.decorator.destroy();
      }
    }

    this.node.decorator = this;

    this.create();
  }

  create = () => {
    this.noiselines = new NoiseLines({
      canvas: this.node,
      showUI: true,
      ...this.props,
    });

    // if the component is interactive,
    // register an intersection observer
    // so the component stops playing
    // when inactive
    if (this.props.interactive || this.props.animated) {
      const { rootMargin, threshold } = this.props;
      this.observer = new IntersectionObserver(this.onIntersection, {
        rootMargin: '0px 0px 0px 0px',
        threshold: 0,
      });
      this.observer.observe(this.node);
    }

    this.node.dispatchEvent(new Event('waves-ready'));
  };

  onIntersection = entries => {
    entries.forEach(({ intersectionRatio }) => {
      if (intersectionRatio > 0) {
        this.noiselines.start();
      } else {
        this.noiselines.stop();
      }
    });
  };

  destroy = () => {
    if (this.observer) this.observer.unobserve(this.node);
    this.noiselines.destroy();
    this.node.decorator = null;
  };
}

export default (node, props) => new Wave(node, props);
