/**
 * This file is part of the Lundhs Realstone Portal 2018 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import registerComponents from 'registerComponents';
import registerDecorations from 'registerDecorations';

let LOADED = false;

if (!global.app) {
  global.app = { register: true };
}

/**
 * This is the sample content.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 */

function bootstrap() {
  if (LOADED) return;
  LOADED = true;

  /**
   * Register react components
   */
  if (global.app.register) {
    global.app.register = false;

    registerComponents();
    registerDecorations();
  }

  document.documentElement.classList.remove('js-loading');
  document.documentElement.classList.add('js-loaded');

  const node = document.getElementById('site-loader');
  if (node) {
    node.parentNode.removeChild(node);
  }
}

document.addEventListener('DOMContentLoaded', bootstrap, false);
window.addEventListener('load', bootstrap, false);
