class FadeIn {
  constructor(node, options = {}) {
    this.props = {
      event: 'ready',
      duration: 500,
      ...options,
    };

    this.node = node;

    /* prevent double decoration */
    if (this.node.decorator) {
      if (this.node.decorator instanceof FadeIn) {
        this.node.decorator.destroy();
      }
    }

    this.node.decorator = this;

    this.create();
  }

  execute = () => {
    this.animation.play();
  };

  create = () => {
    this.animation = this.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: this.props.duration,
    });
    this.animation.pause();
    this.node.addEventListener(this.props.event, this.execute);
  };

  destroy = () => {
    if (this.animation) {
      this.animation.cancel();
    }
  };
}

export default (node, props) => new FadeIn(node, props);
