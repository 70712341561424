import React from 'react';
import objectFitImages from 'object-fit-images';
import picturefill from 'picturefill';
import registerReact from 'utils/registerReact';
import 'picturefill/dist/plugins/mutation/pf.mutation';

/**
 * React components
 */
import MainNav from 'components/MainNav/MainNav';
import Employees from 'components/Employees/Employees';
import Hint from 'components/Hint/Hint';
import CookieConsent from 'components/CookieConsent/CookieConsent';

const Gallery = React.lazy(() => import('./components/Gallery/Gallery'));

const Expandable = React.lazy(() =>
  import('./components/Expandable/Expandable')
);

const Carousel = React.lazy(() => import('./components/Carousel/Carousel'));

const Map = React.lazy(() => import('./components/Map/Map'));

const ContactMap = React.lazy(() =>
  import('./components/ContactMap/ContactMap')
);

const ServiceContact = React.lazy(() =>
  import('./components/ServiceContact/ServiceContact')
);

const ServiceMenu = React.lazy(() =>
  import('./components/ServiceMenu/ServiceMenu')
);

const ServiceTeam = React.lazy(() =>
  import('./components/ServiceTeam/ServiceTeam')
);

const VisionCarousel = React.lazy(() =>
  import('./components/VisionCarousel/VisionCarousel')
);

const Vacancies = React.lazy(() => import('./components/Vacancies/Vacancies'));

const Facade = React.lazy(() => import('./components/Facade/Facade'));

export default () => {
  registerReact('main-nav-component', MainNav);
  registerReact('employees-component', Employees);
  registerReact('hint-component', Hint);
  registerReact('cookie-consent-component', CookieConsent);
  registerReact('gallery-component', Gallery);
  registerReact('expandable-component', Expandable);
  registerReact('carousel-component', Carousel);
  registerReact('map-component', Map);
  registerReact('contact-map-component', ContactMap);
  registerReact('service-contact-component', ServiceContact);
  registerReact('service-menu-component', ServiceMenu);
  registerReact('service-team-component', ServiceTeam);
  registerReact('vision-carousel-component', VisionCarousel);
  registerReact('vacancies-component', Vacancies);
  registerReact('facade-component', Facade);

  picturefill();
  objectFitImages();
};
