import FontFaceObserver from 'fontfaceobserver';
import SplitText from 'gsap/SplitText';

const fontObserver = new FontFaceObserver('poppins', {
  weight: 400,
});

export class BlockLines {
  constructor(node, options = {}) {
    this.props = {
      ...options,
    };
    this.node = node;
    this.node.decorator = this;
    fontObserver.load().then(this.create);
  }

  onResize = () => {
    if (this.split) {
      this.split.revert();
      this.split.split();
    }
  };

  create = () => {
    this.split = new SplitText(this.node, {
      type: 'lines',
      linesClass: 'line',
    });
    window.addEventListener('resize', this.onResize);
  };

  destroy = () => {
    window.removeEventListener('resize', this.onResize);
    if (this.split) {
      this.split.revert();
    }
  };
}

export default (node, props) => new BlockLines(node, props);
