/*
 * This file is part of the Energima Nettside 2021 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * This is the Chevron component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function Chevron({ title }) {
  return (
    <svg
      className="chevron"
      xmlns="http://www.w3.org/2000/svg"
      width="14.796"
      height="8.398"
      viewBox="0 0 14.796 8.398"
    >
      <title>{title}</title>
      <path
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
        d="M6,9l5.984,5.984L17.968,9"
        transform="translate(-4.586 -7.586)"
      />
    </svg>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Chevron.propTypes = {
  title: PropTypes.string,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Chevron.defaultProps = {
  title: 'Chevron',
};

export default Chevron;
