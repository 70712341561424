/*
 * This file is part of the Energima Nettside 2021 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * This is the Logo component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function Logo({ title }) {
  return (
    <span className="svg svg-logo">
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="259.158"
          height="43.459"
          viewBox="0 0 259.158 43.459"
        >
          <title>{title}</title>
          <g transform="translate(-295.132 -255.274)">
            <path
              d="M309.864,255.876A13.266,13.266,0,0,1,323.417,269.5a18.267,18.267,0,0,1-.147,1.989,1.353,1.353,0,0,1-1.326,1.249H301.907a8.53,8.53,0,0,0,8.473,7.885,12.581,12.581,0,0,0,6.556-2.138c.884-.515,1.4-.811,1.988-.071l2.064,2.8a1.143,1.143,0,0,1-.148,1.914,16.932,16.932,0,0,1-10.9,3.683c-8.915,0-14.807-7-14.807-15.467C295.132,263.021,301.024,255.876,309.864,255.876Zm6.7,12.229a6.969,6.969,0,0,0-6.776-6.556,7.457,7.457,0,0,0-7.516,6.556Z"
              transform="translate(0 -0.255)"
            />
            <path
              d="M432.279,255.876A13.265,13.265,0,0,1,445.832,269.5a18.252,18.252,0,0,1-.147,1.989,1.352,1.352,0,0,1-1.327,1.249H424.321a8.533,8.533,0,0,0,8.474,7.885,12.562,12.562,0,0,0,6.553-2.138c.888-.515,1.4-.811,1.992-.071l2.063,2.8a1.143,1.143,0,0,1-.148,1.914,16.924,16.924,0,0,1-10.9,3.683c-8.91,0-14.805-7-14.805-15.467C417.546,263.021,423.441,255.876,432.279,255.876Zm6.7,12.229a6.973,6.973,0,0,0-6.778-6.556,7.452,7.452,0,0,0-7.513,6.556Z"
              transform="translate(-51.769 -0.255)"
            />
            <path
              d="M478.821,258.014a1.448,1.448,0,0,1,1.4-1.4h2.133a1.391,1.391,0,0,1,1.251,1.03l.815,2.5a10.5,10.5,0,0,1,8.615-4.271c2.8,0,6.19.738,5.377,2.727l-1.767,3.606a1.256,1.256,0,0,1-1.767.665,6.726,6.726,0,0,0-2.5-.515,7.767,7.767,0,0,0-6.63,3.607v18.71c0,1.251-.809,1.4-1.992,1.4H480.22a1.446,1.446,0,0,1-1.4-1.4Z"
              transform="translate(-77.682 -0.255)"
            />
            <path
              d="M579.153,258.554a1.449,1.449,0,0,1,1.4-1.4h4.2a1.451,1.451,0,0,1,1.4,1.4v26.664a1.448,1.448,0,0,1-1.4,1.4h-4.2a1.447,1.447,0,0,1-1.4-1.4Z"
              transform="translate(-120.113 -0.795)"
            />
            <path
              d="M714.376,267.443a21.25,21.25,0,0,1,5.818.881c.076-4.64-.809-6.482-4.567-6.482a31.313,31.313,0,0,0-8.25,1.327c-.885.223-1.4-.369-1.619-1.178l-.592-2.726a1.327,1.327,0,0,1,.961-1.7,30.4,30.4,0,0,1,10.164-1.693c9.427,0,10.313,5.747,10.313,13.113v15.688a1.447,1.447,0,0,1-1.4,1.4h-2.062c-.661,0-.955-.292-1.252-1.032l-.736-1.986a12.717,12.717,0,0,1-9.06,3.754c-5.523,0-9.5-3.754-9.5-9.868C702.592,271.567,706.862,267.443,714.376,267.443Zm-.885,14.139a8.719,8.719,0,0,0,6.63-3.681v-4.714a11.081,11.081,0,0,0-5.156-1.181c-3.535,0-5.967,1.846-5.967,4.866A4.43,4.43,0,0,0,713.492,281.583Z"
              transform="translate(-172.315 -0.255)"
            />
            <path
              d="M607.87,258.014a1.451,1.451,0,0,1,1.4-1.4h2.065a1.287,1.287,0,0,1,1.253.882L613.4,260a13.67,13.67,0,0,1,9.722-4.127,10.929,10.929,0,0,1,9.5,4.717c.809-.813,5.082-4.717,10.681-4.717,8.987,0,12.157,6.333,12.157,14v14.8a1.4,1.4,0,0,1-1.4,1.4h-4.124a1.4,1.4,0,0,1-1.4-1.4v-15.1c0-4.64-2.133-7.291-6.113-7.291a10.6,10.6,0,0,0-7.587,3.608,20.73,20.73,0,0,1,.222,3.537v15.246a1.444,1.444,0,0,1-1.4,1.4h-3.9a1.4,1.4,0,0,1-1.4-1.4v-15.1c0-4.714-2.134-7.291-6.26-7.291a8.352,8.352,0,0,0-7.291,4.419v17.971a1.448,1.448,0,0,1-1.4,1.4h-4.128a1.448,1.448,0,0,1-1.4-1.4Z"
              transform="translate(-132.257 -0.255)"
            />
            <path
              d="M372.313,255.876A15.074,15.074,0,0,0,362,260l-.883-2.506a1.193,1.193,0,0,0-1.178-.882H357.8a1.45,1.45,0,0,0-1.4,1.4v26.664a1.448,1.448,0,0,0,1.4,1.4h3.832c1.252,0,1.693-.443,1.693-1.4V266.706c.368-1.031,3.538-4.419,8.177-4.419,3.831,0,6.113,2.577,6.113,7.291v15.1a.969.969,0,0,0,.1.442,1.462,1.462,0,0,0,1.324.959H382.9c1.191,0,1.649-.4,1.7-1.244.006-.037.01-.063.01-.063v-14.9C384.613,262.209,381.3,255.876,372.313,255.876Z"
              transform="translate(-25.911 -0.255)"
            />
            <path
              d="M543.315,255.621H532.854a12.258,12.258,0,0,0-2.85-.347c-7.067,0-12.748,6.06-12.748,13.195a13.637,13.637,0,0,0,2.247,7.512,13.279,13.279,0,0,0,2.385,2.713,17.142,17.142,0,0,0,8.416,3.369l0,0,.031,0c.177.021.339.038.47.049.257.031.579.079.894.143a6.029,6.029,0,0,1,4.784,4.411,4.683,4.683,0,0,1,.1.941,5.649,5.649,0,0,1-3.385,4.989,7.435,7.435,0,0,1-6.09.136,5.708,5.708,0,0,1-3.691-5.125c0-.116.014-.227.023-.341,0,0,0-.009,0-.014a.513.513,0,0,0-.042-.209c-.2-.617-1.217-.682-1.223-.683-.867-.134-3.54-.542-4.255-.542a1.549,1.549,0,0,0-.48.072,1.038,1.038,0,0,0-.67.77,7.478,7.478,0,0,0-.069.946,9.294,9.294,0,0,0,.181,1.738,9.818,9.818,0,0,0,1.513,3.67,12.3,12.3,0,0,0,4.4,3.924A15.14,15.14,0,0,0,530,298.733a14.808,14.808,0,0,0,8.723-2.742,10.8,10.8,0,0,0,4.4-6.618,9.181,9.181,0,0,0,.186-1.762,10.515,10.515,0,0,0-5.3-8.786c-.008,0-.008-.006-.012-.006a13.562,13.562,0,0,0,4.761-10.351,13.408,13.408,0,0,0-2.255-7.424l3.4-.929c.517-.075.809-.736.809-1.251v-1.842A1.353,1.353,0,0,0,543.315,255.621ZM530,276.36c-3.975,0-7.132-3.535-7.132-7.891a7.155,7.155,0,1,1,14.268,0C537.141,272.825,533.984,276.36,530,276.36Z"
              transform="translate(-93.702 0)"
            />
          </g>
        </svg>
      </span>
    </span>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Logo.propTypes = {
  title: PropTypes.string,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Logo.defaultProps = {
  title: 'Energima',
};

export default Logo;
