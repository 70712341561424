/*
 * This file is part of the Akershus Eiendom Nettsted 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * This is the Exit component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function Exit({ title }) {
  return (
    <span className="svg svg-exit">
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16.635"
          height="17.138"
          viewBox="0 0 16.635 17.138"
        >
          <title>{title}</title>
          <g transform="translate(6.767 -4.233)">
            <path
              fill="none"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.5px"
              d="M3,6,16.1,19.6"
              transform="translate(-8)"
            />
            <path
              fill="none"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.5px"
              d="M16.1,6,3,19.6"
              transform="translate(-8)"
            />
          </g>
        </svg>
      </span>
    </span>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Exit.propTypes = {
  title: PropTypes.string,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Exit.defaultProps = {
  title: 'Exit',
};

export default Exit;
