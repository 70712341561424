/*
 * This file is part of the Energima Nettside 2021 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';
import './Spinner.scss';

/**
 * This is the Spinner component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function Spinner({ strong }) {
  const mode = strong ? 'strong' : 'default';
  return (
    <div className={`spinner ${mode}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Spinner.propTypes = {
  strong: PropTypes.bool,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Spinner.defaultProps = {
  strong: false,
};

export default Spinner;
