import { useEffect } from 'react';
import keyboard from 'keymaster';
import useGuid from 'hooks/guid';

keyboard.filter = e => {
  if (e.which === 27) {
    return true;
  }
  var tagName = (e.target || e.srcElement).tagName;
  return !(tagName == 'INPUT' || tagName == 'SELECT' || tagName == 'TEXTAREA');
};

export default (name = 'keys', active = false, keys = {}) => {
  const guid = useGuid(name);

  useEffect(() => {
    if (guid !== null) {
      Object.keys(keys).forEach(key => {
        keyboard(`${key}`, guid, keys[key]);
      });
    }

    return function cleanup() {
      keyboard.deleteScope(guid);
    };
  }, [guid, keys]);

  useEffect(() => {
    if (active) {
      keyboard.setScope(guid);
    } else {
      if (keyboard.getScope() === guid) {
        keyboard.setScope('all');
      }
    }
  }, [active, guid]);
};
