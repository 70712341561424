import registerDecoration from 'utils/registerDecoration';
import inview from 'decorators/inview';
import waves from 'decorators/waves';
import fadeIn from 'decorators/fade-in';
import activeLoop from 'decorators/active-loop';
import blockLines from 'decorators/block-lines';

export default () => {
  // registerDecoration('blockquote p, .body li > span', blockLines);
  registerDecoration('blockquote p', blockLines);

  registerDecoration('[appear]', inview, {
    name: 'appear',
    timeout: 5000,
    threshold: [1],
    props: ['transform'],
    once: true,
  });

  registerDecoration('[stagger]', inview, {
    name: 'stagger',
    timeout: 5000,
    threshold: [0.4, 0.7, 1],
    rootMargin: '0px 0px 50px 0px',
    props: ['transform'],
    once: true,
  });

  registerDecoration(
    '.widget-text h2, .widget-text h3, .widget-text h4, .widget-text h5, .widget-text h6, .widget-text p, .widget-intro p, .widget-documents h2',
    inview,
    {
      name: 'appear',
      timeout: 5000,
      threshold: [0.2, 0.5, 1],
      props: ['transform'],
      once: true,
    }
  );

  registerDecoration(
    '.widget-text ol, .widget-text ul, .widget-documents ul',
    inview,
    {
      name: 'list-stagger',
      timeout: 5000,
      threshold: [0.4, 0.7, 1],
      rootMargin: '0px 0px 50px 0px',
      props: ['transform'],
      once: true,
    }
  );

  registerDecoration('.widget-services .list', activeLoop, {
    selector: 'a',
  });

  registerDecoration('[waves]', fadeIn, {
    event: 'waves-ready',
  });

  setTimeout(() => {
    registerDecoration('[waves]', waves, {
      color: '#ff0000',
    });
  }, 1600);
};
