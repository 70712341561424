import { useState, useEffect } from 'react';

export default (limit = 1024) => {
  const [enhanced, setEnhanced] = useState(false);

  useEffect(() => {
    function onResize() {
      setEnhanced(window.innerWidth >= limit);
    }

    window.addEventListener('resize', onResize);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [limit]);

  return enhanced;
};
