/*
 * This file is part of the Energima Nettside 2021 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * This is the User component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function User({ title }) {
  return (
    <span className="svg svg-user">
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21.307"
          height="23.658"
          viewBox="0 0 21.307 23.658"
        >
          <title>{title}</title>
          <g transform="translate(-3.467 -2.658)">
            <path
              fill="none"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.5px"
              d="M23.523,22.961V20.61a4.7,4.7,0,0,0-4.7-4.7h-9.4a4.7,4.7,0,0,0-4.7,4.7v2.351"
              transform="translate(0 2.105)"
            />
            <path
              fill="none"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.5px"
              d="M13.418,13.311a4.7,4.7,0,1,0-4.7-4.7A4.7,4.7,0,0,0,13.418,13.311Z"
              transform="translate(0.702 0)"
            />
          </g>
        </svg>
      </span>
    </span>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
User.propTypes = {
  title: PropTypes.string,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
User.defaultProps = {
  title: 'User',
};

export default User;
