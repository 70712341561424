export class ActiveLoop {
  constructor(node, options = {}) {
    this.props = {
      selector: '.item',
      delay: 2000,
      rootMargin: '0px 0px 0px 0px',
      threshold: [0, 1],
      ...options,
    };

    this.interval = null;
    this.node = node;
    this.list = Array.from(node.querySelectorAll(this.props.selector));

    this.state = {
      inview: false,
      hover: false,
      current: 0,
    };

    /* prevent double decoration */
    if (this.node.decorator) {
      if (this.node.decorator instanceof Wave) {
        this.node.decorator.destroy();
      }
    }

    this.node.decorator = this;

    if (this.list.length) {
      this.create();
    }
  }

  setState = (change = {}) => {
    const updated = {
      ...this.state,
      ...change,
    };
    if (JSON.stringify(this.state) !== JSON.stringify(updated)) {
      this.state = updated;
      requestAnimationFrame(this.render);
    }
  };

  registerObserver = () => {
    const { rootMargin, threshold } = this.props;
    this.observer = new IntersectionObserver(this.onIntersection, {
      rootMargin,
      threshold,
    });
    this.observer.observe(this.node);
  };

  onIntersection = entries => {
    entries.forEach(({ intersectionRatio }) => {
      this.setState({
        inview: intersectionRatio > 0,
        current: 0,
      });
    });
  };

  next = () => {
    let next = this.state.current + 1;
    if (next > this.list.length - 1) {
      next = 0;
    }

    this.setState({ current: next });
  };

  onMouseEnter = () => {
    this.setState({
      hover: true,
    });
  };

  onMouseLeave = () => {
    this.setState({
      hover: false,
    });
  };

  render = () => {
    clearInterval(this.interval);
    this.list.forEach(node => {
      node.classList.remove('active');
    });

    if (!this.state.hover) {
      this.list[this.state.current].classList.add('active');
    }

    if (this.state.inview && !this.state.hover) {
      this.interval = setInterval(this.next, this.props.delay);
    }
  };

  create = () => {
    this.node.addEventListener('mouseenter', this.onMouseEnter);
    this.node.addEventListener('mouseleave', this.onMouseLeave);
    this.registerObserver();
  };

  destroy = () => {
    clearInterval(this.interval);
    this.observer.unobserve(this.node);
    this.node.removeEventListener('mouseenter', this.onMouseEnter);
    this.node.removeEventListener('mouseleave', this.onMouseLeave);
  };
}

export default (node, props) => new ActiveLoop(node, props);
