/*
 * This file is part of the Energima Nettside 2021 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * This is the Magnifier component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function Magnifier({ title }) {
  return (
    <span className="svg svg-square svg-magnifier">
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20.415"
          height="20.415"
          viewBox="0 0 20.415 20.415"
        >
          <title>{title}</title>
          <g transform="translate(1 1)">
            <path
              fill="none"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2px"
              d="M11,19a8,8,0,1,0-8-8A8,8,0,0,0,11,19Z"
              transform="translate(-3 -3)"
            />
            <path
              fill="none"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2px"
              d="M21,21l-4.35-4.35"
              transform="translate(-3 -3)"
            />
          </g>
        </svg>
      </span>
    </span>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Magnifier.propTypes = {
  title: PropTypes.string,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Magnifier.defaultProps = {
  title: 'Magnifier',
};

export default Magnifier;
