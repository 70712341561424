/*
 * This file is part of the Energima Nettside 2021 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select';
import Chevron from 'components/Icons/Chevron/Chevron';
import useTouch from 'hooks/touch';
import useEnhanced from 'hooks/enhanced';
import './Select.scss';

/**
 * This is the Select component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function Select({ name, labels, options, value: selected, onChange }) {
  const touch = useTouch();
  const enhanced = useEnhanced(768);
  const active = options.findIndex(({ value }) => value === selected);

  function renderSelect() {
    if (touch && !enhanced) {
      return (
        <>
          <label htmlFor={`employee-${name}`} className="sr-only">
            {labels.label}
          </label>
          <select
            id={`employee-${name}`}
            value={selected}
            onChange={({ target: { value } }) => {
              onChange({ [name]: value });
            }}
          >
            <option value="">{labels.select}</option>
            {options.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        </>
      );
    }

    const items = options.map(({ icon, label, value }) => {
      if (icon) {
        return {
          label: (
            <span className="item">
              <span
                className={`svg svg-square svg-service-icon svg-${icon.name}`}
              >
                <span dangerouslySetInnerHTML={{ __html: icon.xml }} />
              </span>
              <span className="text">{label}</span>
            </span>
          ),
          value,
        };
      }

      return { label, value };
    });

    return (
      <>
        <label id={`employee-${name}`} className="sr-only">
          {labels.label}
        </label>
        <ReactSelect
          classNamePrefix="filter"
          aria-labelledby={`employee-${name}`}
          placeholder={labels.select}
          menuPlacement="auto"
          minMenuHeight={280}
          options={items}
          value={items[active] || null}
          components={{
            DropdownIndicator: props => (
              <components.DropdownIndicator {...props}>
                <Chevron title={labels.select} />
              </components.DropdownIndicator>
            ),
          }}
          onChange={e => {
            onChange({ [name]: e ? e.value : '' });
          }}
        />
      </>
    );
  }

  return (
    <div className={`field-row field-${name}`} appear="true">
      {renderSelect()}
    </div>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Select.propTypes = {
  name: PropTypes.string.isRequired,
  labels: PropTypes.shape({}).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      icon: PropTypes.shape({
        name: PropTypes.string.isRequired,
        xml: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Select.defaultProps = {
  name: 'select',
  labels: {
    label: 'Select',
    select: 'Select',
  },
  options: [],
  value: '',
  onChange: () => {},
};

export default Select;
