/*
 * This file is part of the Energima Nettside 2021 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';
import './Hamburger.scss';

/**
 * This is the Hamburger component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function Hamburger({ title }) {
  return (
    <span className="svg svg-hamburger">
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22.803"
          height="16.035"
          viewBox="0 0 22.803 16.035"
        >
          <title>{title}</title>
          <g transform="translate(-1.75 -4.75)">
            <path className="a" d="M3,12H23.3" transform="translate(0 0.768)" />
            <path className="a" d="M3,6H23.3" />
            <path className="a" d="M3,18H23.3" transform="translate(0 1.535)" />
          </g>
        </svg>
      </span>
    </span>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Hamburger.propTypes = {
  title: PropTypes.string,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Hamburger.defaultProps = {
  title: 'Menu',
};

export default Hamburger;
