/*
 * This file is part of the Energima Nettside 2021 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import EmployeeOverlay from 'components/EmployeeOverlay/EmployeeOverlay';
import Menu from 'components/Menu/Menu';
import Logo from 'components/Icons/Logo/Logo';
import 'utils/menu-scroll';
import './MainNav.scss';

const backdrop = document.getElementById('backdrop');

/**
 * This is the MainNav component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function MainNav({ home, employees: empConfig, menu: menuConfig }) {
  const employees = useRef(null);
  const menu = useRef(null);

  const [active, setActive] = useState('employees');
  const [scrollBarWidth, setScrollBarWidth] = useState(
    window.innerWidth - document.documentElement.clientWidth
  );
  const [state, setState] = useState({
    menu: 'closed',
    employees: 'closed',
  });

  useEffect(() => {
    function onClick() {
      employees.current.close();
      menu.current.close();
    }

    if (backdrop) {
      backdrop.addEventListener('click', onClick);
    }

    return () => {
      if (backdrop) {
        backdrop.removeEventListener('click', onClick);
      }
    };
  }, []);

  useEffect(() => {
    if (document.body.style.overflow !== 'hidden') {
      setScrollBarWidth(
        window.innerWidth - document.documentElement.clientWidth
      );
    }
  }, [JSON.stringify(state)]);

  useEffect(() => {
    if (state.menu === 'closed' && state.employees === 'closed') {
      document.documentElement.style.marginRight = '';
      document.body.style.overflow = '';
    } else {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.marginRight = `${scrollBarWidth}px`;
    }

    if (
      ['opening', 'open'].includes(state.menu) ||
      ['opening', 'open'].includes(state.employees)
    ) {
      document.body.classList.add('slide-open');
    } else {
      document.body.classList.remove('slide-open');
    }
  }, [JSON.stringify(state)]);

  return (
    <nav className="main-nav">
      <EmployeeOverlay
        ref={employees}
        {...empConfig}
        enabled={active === 'employees'}
        onFocus={() => {
          setActive('employees');
        }}
        onOpen={() => {
          menu.current.close();
        }}
        onMenu={() => {
          setActive('menu');
          employees.current.close();
          menu.current.open();
        }}
        onState={state => {
          setState(prev => ({
            ...prev,
            employees: state,
          }));
        }}
      />
      <a className="home" href={home.url}>
        <Logo title={home.text} />
      </a>
      <Menu
        ref={menu}
        home={home}
        {...menuConfig}
        enabled={active === 'menu'}
        onFocus={() => {
          setActive('menu');
        }}
        onOpen={() => {
          employees.current.close();
        }}
        onUser={() => {
          setActive('employees');
          menu.current.close();
          employees.current.open();
        }}
        onState={state => {
          setState(prev => ({
            ...prev,
            menu: state,
          }));
        }}
      />
    </nav>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
MainNav.propTypes = {
  home: PropTypes.shape({
    url: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  employees: PropTypes.object,
  menu: PropTypes.object,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
MainNav.defaultProps = {
  home: {
    url: '/',
    text: 'Home',
  },
  employees: null,
  menu: null,
};

export default MainNav;
