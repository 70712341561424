/*
 * This file is part of the Energima Nettside 2021 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FuzzySearch from 'fuzzy-search';
import classnames from 'classnames';
import useBreakpoints from 'hooks/breakpoints';
import Arrow from 'components/Icons/Arrow/Arrow';
import Filter from './Filter/Filter';
import Person from './Person/Person';

const DELAY = 300;

function getMap(list) {
  const map = {};

  list.forEach(({ slug, ...rest }) => {
    map[slug] = {
      slug,
      ...rest,
    };
  });

  return map;
}

function search(list, frase, keys = [], exclude = []) {
  const searcher = new FuzzySearch(list, keys, {
    sort: true,
  });
  return searcher.search(frase).filter(({ id }) => !exclude.includes(id));
}

function filter(companies, services, employees, state, limit) {
  const componentMap = getMap(companies);
  const servicesMap = getMap(services);

  const selectedManagement = !!state.management;
  const selectedCompany = state.company.trim();
  const selectedServices = state.service.trim();
  const frase = state.search.trim();

  const filtered = employees
    .map(props => ({
      company: '',
      expertise: [],
      ...props,
    }))
    .filter(({ management }) => {
      if (selectedManagement) {
        return management;
      }

      return true;
    })
    .filter(({ company }) => {
      if (selectedCompany.length) {
        return company === selectedCompany;
      }

      return true;
    })
    .filter(({ expertise }) => {
      if (selectedServices.length) {
        return expertise.indexOf(selectedServices) !== -1;
      }

      return true;
    })
    .map(({ company, expertise, ...props }) => {
      return {
        ...props,
        company: componentMap[company],
        expertise: expertise.map(slug => ({
          ...servicesMap[slug],
        })),
      };
    });

  if (frase.length) {
    const personalia = search(filtered, frase, ['name', 'email', 'phone']);

    const matches = personalia.map(({ id }) => id);

    const roles = search(filtered, frase, ['role', 'company.name'], matches);

    const result = [...personalia, ...roles];

    return result;
  }

  if (state.company.length || state.service.length || state.management) {
    return filtered;
  }

  return filtered.slice(0, limit);
}

function getAnimationTiming(columns, index) {
  const mod = index % columns;
  if (mod > 0) {
    return { delay: DELAY * mod };
  }
  return {};
}

/**
 * This is the Employees component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function Employees({
  forwardRef,
  id,
  className,
  columns: columnsSettings,
  companies,
  services,
  employees,
  level,
  limit: defaultLimit,
  page,
  animate,
  labels,
}) {
  const [state, setState] = useState({
    management: false,
    company: '',
    service: '',
    search: '',
  });
  const [limit, setLimit] = useState(defaultLimit);

  const columns = useBreakpoints(columnsSettings);

  const { management, company, service, search } = state;

  useEffect(() => {
    const delta = columns - (limit % columns);

    if (delta !== parseInt(columns)) {
      setLimit(prev => prev + delta);
    }
  }, [columns, limit]);

  useEffect(() => {
    if (management) {
      setState({
        ...state,
        company: '',
        service: '',
        search: '',
      });
    }
  }, [management]);

  useEffect(() => {
    if (company || service || search) {
      setState({
        ...state,
        management: false,
      });
    }
  }, [company, service, search]);

  const filtered = filter(companies, services, employees, state, limit);

  function renderPersons() {
    if (!filtered.length) {
      return <div className="nomatch">{labels.nomatch}</div>;
    }

    return filtered.map(({ id, ...props }, index) => (
      <Person
        key={id}
        level={level}
        animate={animate}
        animationTiming={getAnimationTiming(columns, index)}
        {...props}
      />
    ));
  }

  function renderMore() {
    if (employees.length === limit) return null;

    if (management) return null;
    if (company.length) return null;
    if (service.length) return null;
    if (search.length) return null;

    const page = parseInt(columns);

    return (
      <div className="more">
        <button
          appear="true"
          onClick={() => {
            setLimit(prev => prev + page);
          }}
        >
          <span>
            <Arrow down />
            <span className="link">{labels.more}</span>
          </span>
        </button>
      </div>
    );
  }

  return (
    <div
      id={id}
      ref={forwardRef}
      className={classnames('employees', className)}
    >
      <Filter
        companies={companies}
        services={services}
        state={{
          management,
          company,
          service,
          search,
        }}
        labels={labels}
        onChange={change => {
          setState(state => ({
            ...state,
            ...change,
          }));
        }}
      />
      <div className="persons" appear="true" threshold="{[0.05, 1]}">
        {renderPersons()}
      </div>
      {renderMore()}
    </div>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Employees.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  columns: PropTypes.object.isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      company: PropTypes.string,
      role: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      expertise: PropTypes.array,
      portrait: PropTypes.object,
    })
  ).isRequired,
  level: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  limit: PropTypes.number.isRequired,
  animate: PropTypes.bool,
  labels: PropTypes.shape({
    search: PropTypes.string.isRequired,
    management: PropTypes.shape({
      display: PropTypes.string.isRequired,
      all: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
    company: PropTypes.shape({
      label: PropTypes.string.isRequired,
      select: PropTypes.string.isRequired,
    }).isRequired,
    service: PropTypes.shape({
      label: PropTypes.string.isRequired,
      select: PropTypes.string.isRequired,
    }).isRequired,
    nomatch: PropTypes.string.isRequired,
    more: PropTypes.string.isRequired,
  }).isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Employees.defaultProps = {
  className: null,
  columns: {},
  companies: [],
  services: [],
  employees: [],
  level: 2,
  limit: 6,
  animate: false,
  labels: {
    search: 'Search',
    management: {
      display: 'Display:',
      all: 'All',
      title: 'Management and administration',
    },
    company: {
      label: 'Company',
      select: 'Select company',
    },
    service: {
      label: 'Service',
      select: 'Select service',
    },
    nomatch: 'The selection have no match.',
    more: 'Load more',
  },
};

export default forwardRef((props, ref) => (
  <Employees {...props} forwardRef={ref} />
));
