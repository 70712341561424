/*
 * This file is part of the Det Norske Teatret Nettside 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import objectFitImages from 'object-fit-images';
import picturefill from 'picturefill';

/**
 * This is the Picture component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
const Picture = ({
  sources,
  img,
  width,
  height,
  onResize,
  onLoad,
  onError,
  ...props
}) => {
  const imageRef = useRef(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    const trigger = () => {
      onResize(imageRef.current);
    };

    window.addEventListener('resize', trigger);

    return function cleanup() {
      window.removeEventListener('resize', trigger);
    };
  });

  useEffect(() => {
    picturefill({
      elements: [imageRef.current],
    });
    objectFitImages(imageRef.current);
  });

  const attr = img.attr || {};

  if (!sources.length) {
    return (
      <img
        className={classnames({ error })}
        ref={imageRef}
        width={width}
        height={height}
        {...img}
        {...attr}
        {...props}
        onError={() => {
          setError(true);
          onError();
        }}
        onLoad={e => {
          setError(false);
          onResize(imageRef.current);
          onLoad(e);
        }}
      />
    );
  }

  return (
    <picture className={classnames({ error })}>
      {sources.map((props, key) => (
        <source key={key} {...props} />
      ))}
      <img
        ref={imageRef}
        width={width}
        height={height}
        {...img}
        onError={() => {
          setError(true);
          onError();
        }}
        onLoad={e => {
          setError(false);
          onResize(imageRef.current);
          onLoad(e);
        }}
      />
    </picture>
  );
};

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Picture.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.object),
  img: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  onLoad: PropTypes.func,
  onError: PropTypes.func,
  onResize: PropTypes.func,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Picture.defaultProps = {
  sources: [],
  img: {},
  width: null,
  height: null,
  onError: () => {},
  onLoad: () => {},
  onResize: () => {},
};

export default Picture;
