export default (target, options = {}, callback = () => {}) => {
  const settings = {
    alignTop: false,
    offset: 0,
    ...options,
  };
  if (typeof window.scrollTo === 'function') {
    if (target === window) {
      smoothScrollTo(0, callback);
    } else {
      const adminbar = document.getElementById('adminbar');
      let top =
        target.getBoundingClientRect().top +
        (window.pageYOffset || document.documentElement.scrollTop);
      let offset = 0;

      if (adminbar) {
        offset = adminbar.offsetHeight;
      }

      if (settings.alignTop) {
        top = top - offset - settings.offset;
      } else {
        const heightDiff = window.innerHeight - offset - target.offsetHeight;
        if (heightDiff > 1) {
          top = top - heightDiff / 2;
        } else {
          top = top - offset;
        }
      }

      smoothScrollTo(top, callback);
    }
  }
};

function lerp(v0, v1, t) {
  return v0 * (1 - t) + v1 * t;
}

function ease(v) {
  return 0.5 * (1 - Math.cos(Math.PI * v));
}

function smoothScrollTo(targetY, cb) {
  let running = true;
  let frameId = null;

  function stop() {
    if (!running) return;
    running = false;

    if (frameId) {
      cancelAnimationFrame(frameId);
    }

    if (typeof cb === 'function') {
      cb();
    }
  }

  const startY = window.pageYOffset || document.documentElement.scrollTop;

  if (startY === targetY) {
    stop();
  }

  const startTime = Date.now();

  // const duration = Math.abs(targetY - startY) * 0.468;
  const duration = 468;

  function tick() {
    const elapsed = Date.now() - startTime;
    const fraction = elapsed / duration;

    window.scrollTo(0, lerp(startY, targetY, ease(fraction)));

    if (fraction >= 1) {
      stop();
    }

    if (running) {
      frameId = requestAnimationFrame(tick);
    }
  }

  tick();
}
