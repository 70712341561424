import { useState, useEffect } from 'react';

function getBreakpoints(options) {
  return Object.keys(options)
    .map(key => ({ key, value: options[key] }))
    .sort((a, b) => {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;
      return n0;
    });
}

function getActive(breakpoints) {
  return breakpoints.reduce((accumulator, { key, value }) => {
    if (window.innerWidth >= value) {
      return key;
    }
    return accumulator;
  }, null);
}

export default (options = {}) => {
  const breakpoints = getBreakpoints(options);
  const [active, setActive] = useState(getActive(breakpoints));

  useEffect(() => {
    function onResize() {
      setActive(getActive(breakpoints));
    }

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [JSON.stringify(breakpoints)]);

  return active;
};
