export class Inview {
  constructor(node, options = {}) {
    this.props = {
      name: 'inview',
      rootMargin: '0px 0px 0px 0px',
      threshold: 1.0,
      timeout: 0,
      once: false,
      props: [],
      ...options,
    };
    this.node = node;
    this.node.decorator = this;

    this.node.classList.add(`${this.props.name}-enter`);
    this.timeout = setTimeout(this.registerObserver, 120);
  }

  registerObserver = () => {
    const { rootMargin, threshold } = this.props;
    this.observer = new IntersectionObserver(this.onIntersection, {
      rootMargin,
      threshold,
    });
    this.observer.observe(this.node);
  };

  removeClasses = e => {
    const { props, once } = this.props;
    if (e) {
      if (props.length > 0) {
        if (props.indexOf(e.propertyName) !== -1) {
          clearTimeout(this.timeout);
          this.node.classList.remove(`${this.props.name}-enter`);
          this.node.classList.remove(`${this.props.name}-enter-active`);
        }
      } else {
        clearTimeout(this.timeout);
        this.node.classList.remove(`${this.props.name}-enter`);
        this.node.classList.remove(`${this.props.name}-enter-active`);
      }
    } else {
      clearTimeout(this.timeout);
      this.node.classList.remove(`${this.props.name}-enter`);
      this.node.classList.remove(`${this.props.name}-enter-active`);
    }

    if (once) {
      this.observer.unobserve(this.node);
    }
  };

  onIntersection = entries => {
    entries.forEach(({ intersectionRatio }) => {
      if (intersectionRatio > 0) {
        this.timeout = setTimeout(this.removeClasses, this.props.timeout);
        this.node.classList.add(`${this.props.name}-enter-active`);
      }
    });
  };
}

export default (node, props) => new Inview(node, props);
