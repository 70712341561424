/*
 * This file is part of the Energima Nettside 2021 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import addSwipeEvent from 'swipe-listener/dist/swipe-listener.min';
import Employees from 'components/Employees/Employees';
import Exit from 'components/Icons/Exit/Exit';
import User from 'components/Icons/User/User';
import Logo from 'components/Icons/Logo/Logo';
import Hamburger from 'components/Icons/Hamburger/Hamburger';

import useMenu from 'hooks/menu';
import useSlide from 'hooks/slide';

const tray = document.getElementById('employees');

const columns = {
  1: 0,
  2: 365,
  3: 760,
};

/**
 * This is the EmployeeOverlay component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function EmployeeOverlay(
  {
    enabled,
    heading,
    intro,
    home,
    labels,
    onFocus,
    onOpen,
    onMenu,
    onState,
    ...props
  },
  ref
) {
  const [buttonNode, submenuNode, open, setOpen] = useMenu(
    'employees',
    enabled
  );
  const { state, ...slide } = useSlide(tray);

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
    },
    close: () => {
      setOpen(false);
    },
  }));

  useEffect(() => {
    if (open) {
      onOpen();
      slide.open();
    }

    return () => {
      if (open) {
        slide.close();
      }
    };
  }, [open]);

  useEffect(() => {
    let listener = null;

    function onSwipe(e) {
      if (e.detail.directions.left) {
        slide.close();
        setOpen(false);
      }
    }

    if (open && tray) {
      listener = addSwipeEvent(tray);
      tray.addEventListener('swipe', onSwipe);
    }

    return () => {
      if (tray) {
        tray.removeEventListener('swipe', onSwipe);
        if (listener) {
          listener.off();
        }
      }
    };
  }, [open]);

  useEffect(() => {
    onState(state);
  }, [state]);

  function renderEmployees() {
    if (!open && state === 'open') {
      return null;
    }

    if (!open && state === 'closed') {
      return null;
    }

    const content = (
      <div ref={submenuNode}>
        <div className="overlay-top top">
          <button
            className="close"
            role="menuitem"
            onClick={() => {
              setOpen(false);
            }}
          >
            <Exit title={labels.close} />
          </button>
          <a className="home" href={home.url}>
            <Logo title={home.text} />
          </a>
          <button className="menu-toggle" onClick={onMenu}>
            <Hamburger title={labels.menu} />
          </button>
        </div>
        <div className="heading">
          <h2>{heading}</h2>
          {intro ? <p dangerouslySetInnerHTML={{ __html: intro }} /> : null}
        </div>
        <Employees
          id="employees"
          level={3}
          columns={columns}
          animate={state === 'open'}
          {...props}
          labels={labels}
        />
      </div>
    );

    if (tray) {
      return createPortal(content, tray);
    }

    return content;
  }

  return (
    <>
      <button
        ref={buttonNode}
        className="employee-toggle"
        aria-label={open ? labels.close : labels.open}
        aria-haspopup="true"
        aria-controls="employees"
        aria-expanded={open ? 'true' : 'false'}
        onFocus={onFocus}
        onClick={() => {
          setOpen(true);
        }}
      >
        <span>
          <User title={labels.title} />
          <span className="text">{labels.title}</span>
        </span>
      </button>
      {renderEmployees()}
    </>
  );
}

EmployeeOverlay = forwardRef(EmployeeOverlay);

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
EmployeeOverlay.propTypes = {
  enabled: PropTypes.bool,
  heading: PropTypes.string,
  intro: PropTypes.string,
  home: PropTypes.shape({
    url: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  labels: PropTypes.shape({
    title: PropTypes.string,
    open: PropTypes.string,
    close: PropTypes.string,
  }).isRequired,
  onFocus: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onMenu: PropTypes.func.isRequired,
  onState: PropTypes.func.isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
EmployeeOverlay.defaultProps = {
  enabled: false,
  heading: 'Our staff',
  intro: null,
  home: {
    url: '/',
    text: 'Home',
  },
  labels: {
    title: 'Find people',
    open: 'Close',
    close: 'Close',
    search: 'Search',
    company: {
      label: 'Company',
      select: 'Select company',
    },
    service: {
      label: 'Service',
      select: 'Select service',
    },
  },
  onFocus: () => {},
  onOpen: () => {},
  onMenu: () => {},
  onState: () => {},
};

export default EmployeeOverlay;
