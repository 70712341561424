import { Pane } from 'tweakpane';
import random from './random';
import { scaleCanvas, mapRange, getNDC, debounce } from './utils';

const rand = random();
const ROTATION_PADDING = 0.1;

export default class NoiseLines {
  constructor(options) {
    this.canvas = options.canvas;
    this.ctx = scaleCanvas(this.canvas);

    this.interactive = options.interactive;
    this.animated = options.animated;
    this.lineColor = options.lineColor;
    this.lineOpacity = options.lineOpacity;

    // tweakable params
    this.PARAMS = {
      ...options,
    };

    if (options.enableGui) {
      this.pane = new Pane({
        container: this.canvas.parentNode,
        expanded: false,
        title: 'Settings',
      });

      this.pane.addInput(this.PARAMS, 'timeScale', {
        min: 0,
        max: 1,
      });
      this.pane.addInput(this.PARAMS, 'numLines', {
        min: 1,
        max: 50,
        step: 1,
      });
      this.pane.addInput(this.PARAMS, 'lineSegments', {
        min: 2,
        max: 500,
        step: 1,
      });
      this.pane.addInput(this.PARAMS, 'noiseOffset', {
        min: 0,
        max: 0.03,
      });
      this.pane.addInput(this.PARAMS, 'frequencyMax', {
        min: 0,
        max: 0.01,
      });
      this.pane.addInput(this.PARAMS, 'frequencyMin', {
        min: 0,
        max: 0.01,
      });
      this.pane.addInput(this.PARAMS, 'minAmplitude', {
        min: 0,
        max: 1,
      });
      this.pane.addInput(this.PARAMS, 'maxAmplitude', {
        min: 0,
        max: 1,
      });
      this.pane
        .addButton({
          title: 'Generate',
        })
        .on('click', () => {
          this.createLines();
        });
    }

    this.mouse = {
      pos: [this.width * 0.5, this.height * 0.5],
      eased: [this.width * 0.5, this.height * 0.5],
    };

    this.mouseNormalized = {
      pos: [0, 0],
      eased: [0, 0],
    };

    if (this.interactive) {
      document.body.addEventListener('touchmove', this.onMouseMove);
      document.body.addEventListener('mousemove', this.onMouseMove);
    }

    this.time = 0;
    this.lastTime = Date.now();
    this.isAnimating = false;
    this.mouseDown = false;
    this.animationFrame = null;
    this.easeFactor = options.easeFactor || 10;
    this.mouseMoved = false;
    this.rotation = 0;

    // get canvas width
    const rect = this.canvas.getBoundingClientRect();

    this.width = rect.width;
    this.height = rect.height;
    this.centerX = this.width * 0.5;
    this.centerY = this.height * 0.5;
    this.rotPadding = this.width * ROTATION_PADDING;

    this.ctx.translate(this.centerX, 0);

    this.createLines();

    this.start();

    // add resize event
    window.addEventListener('resize', this.onResize);
  }

  start() {
    if (this.isAnimating) return;

    this.lastTime = Date.now();

    if (this.animated) {
      this.isAnimating = true;
    }

    this.animate();
  }

  stop() {
    if (this.isAnimating) {
      this.isAnimating = false;
      cancelAnimationFrame(this.animationFrame);
    }
  }

  onMouseMove = ({ type, touches, clientX, clientY }) => {
    const pos = [];
    if (type === 'touchmove') {
      pos[0] = touches[0].clientX;
      pos[1] = touches[0].clientY;
    } else {
      pos[0] = clientX;
      pos[1] = clientY;
    }

    this.mouseMoved = true;
    this.mouse.pos = pos;
    this.mouseNormalized.pos = getNDC(pos);
  };

  animate = () => {
    if (this.isAnimating) {
      this.animationFrame = requestAnimationFrame(this.animate);
    }

    // get delta time
    const currentTime = Date.now();
    const dt = (currentTime - this.lastTime) / 1000;

    // update time
    this.lastTime = currentTime;
    this.time += dt;

    // update mouse easing
    this.mouse.eased[0] +=
      (this.mouse.pos[0] - this.mouse.eased[0]) / this.easeFactor;
    this.mouse.eased[1] +=
      (this.mouse.pos[1] - this.mouse.eased[1]) / this.easeFactor;

    this.mouseNormalized.eased[0] +=
      (this.mouseNormalized.pos[0] - this.mouseNormalized.eased[0]) /
      this.easeFactor;
    this.mouseNormalized.eased[1] +=
      (this.mouseNormalized.pos[1] - this.mouseNormalized.eased[1]) /
      this.easeFactor;

    this.update();
    this.render(dt, this.time);
  };

  update() {}

  clear() {
    this.ctx.clearRect(
      -this.centerX - this.rotPadding,
      -this.rotPadding,
      this.width + this.rotPadding * 2,
      this.height + this.rotPadding * 2
    );
  }

  resize() {
    // reset transform matrix to identity
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);

    const rect = this.canvas.getBoundingClientRect();

    this.width = rect.width;
    this.height = rect.height;
    this.centerX = this.width * 0.5;
    this.centerY = this.height * 0.5;
    this.rotPadding = this.width * ROTATION_PADDING;
    this.ctx = scaleCanvas(this.canvas);
    this.ctx.translate(this.centerX, 0);

    this.createLines();
    this.render(0.16, this.time);
  }

  onResize = debounce(() => this.resize());

  destroy() {
    this.isAnimating = false;
    document.body.removeEventListener('touchmove', this.onMouseMove);
    document.body.removeEventListener('mousemove', this.onMouseMove);
    if (this.pane) this.pane.dispose();
    window.removeEventListener('resize', this.onResize);
  }

  createLines() {
    this.lines = [];

    for (let i = 0; i < this.PARAMS.numLines; i++) {
      this.lines.push(this.createLine());
    }
  }

  createLine() {
    const dist = (this.rotPadding + this.width) / this.PARAMS.lineSegments + 1;
    const line = {
      points: [],
      color: this.lineColor,
      width: rand.range(0.1, 0.5),
    };
    for (let i = 0; i < this.PARAMS.lineSegments; i++) {
      const x = -this.centerX - this.rotPadding + i * dist;
      line.points.push({
        x,
        y: this.centerY,
      });
    }
    return line;
  }

  drawLine(line, num, time) {
    this.ctx.lineWidth = line.width;
    this.ctx.beginPath();
    this.ctx.globalAlpha = this.lineOpacity;

    const points = line.points;

    for (let i = 0; i < points.length; i++) {
      const p = points[i];
      const n = rand.noise2D(
        p.x * this.frequency - Math.sin(time * 0.05),
        time * this.PARAMS.timeScale + num * this.PARAMS.noiseOffset
      );
      p.y = this.centerY + n * this.amplitude;

      if (i === 0) {
        this.ctx.moveTo(p.x, p.y);
      }
      this.ctx.lineTo(p.x, p.y);
    }

    this.ctx.stroke();
  }

  render(dt, time) {
    this.clear();

    const mouseX = this.mouseNormalized.eased[0];
    const mouseY = this.mouseNormalized.eased[1];

    // rotate back to origin
    this.ctx.rotate(-this.rotation);

    this.rotation =
      this.mouseNormalized.eased[0] * this.mouseNormalized.eased[1] * 0.1;

    this.ctx.rotate(this.rotation);

    this.frequency = mapRange(
      mouseX,
      -1,
      1,
      this.PARAMS.frequencyMin,
      this.PARAMS.frequencyMax
    );
    this.amplitude = mapRange(
      mouseY,
      -1,
      1,
      this.PARAMS.minAmplitude * this.height,
      this.PARAMS.maxAmplitude * this.height
    );

    for (let i = 0; i < this.lines.length; i++) {
      const line = this.lines[i];
      this.ctx.strokeStyle = line.color;
      this.drawLine(line, i, time);
    }
  }
}
