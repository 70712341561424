import React, { Suspense } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { getProps, getChildren } from 'utils/DOM';
import Spinner from 'components/Spinner/Spinner';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

const listeners = [];

function renderComponent(node, Component) {
  const key = node.nodeName.toLowerCase().replace('-component', '');
  const props = getProps(node);

  if (node.chachedChildren === undefined) {
    node.chachedChildren = getChildren(node);
  }
  props.children = node.chachedChildren;
  render(
    <ErrorBoundary>
      <Suspense
        fallback={
          <div className={`loadable-loader ${key} ${key}-loader`}>
            <Spinner />
          </div>
        }
      >
        <Component {...props} container={node} />
      </Suspense>
    </ErrorBoundary>,
    node
  );
}

function checkAdded(added) {
  if (['STYLE', '#text', 'SCRIPT'].indexOf(added.nodeName) === -1) {
    const nodeNames = listeners.map(({ selector }) => selector.toLowerCase());
    const nodeName = added.nodeName.toLowerCase();
    listeners.forEach(({ selector, Component }) => {
      try {
        if (
          nodeNames.indexOf(nodeName) !== -1 &&
          nodeName === selector.toLowerCase()
        ) {
          renderComponent(added, Component);
        } else {
          const nodes = added.querySelectorAll(selector);
          Array.prototype.forEach.call(nodes, node => {
            renderComponent(node, Component);
          });
        }
        /* eslint-disable no-empty */
      } catch (e) {}
    });
  }
}

function checkRemoved(removed) {
  if (['STYLE', '#text', 'SCRIPT'].indexOf(removed.nodeName) === -1) {
    const nodeNames = listeners.map(({ selector }) => selector.toLowerCase());
    const nodeName = removed.nodeName.toLowerCase();
    listeners.forEach(({ selector }) => {
      try {
        if (
          nodeNames.indexOf(nodeName) !== -1 &&
          nodeName === selector.toLowerCase()
        ) {
          unmountComponentAtNode(removed);
        } else {
          const nodes = removed.querySelectorAll(selector);
          Array.prototype.forEach.call(nodes, node => {
            unmountComponentAtNode(node);
          });
        }
        /* eslint-disable no-empty */
      } catch (e) {}
    });
  }
}

function updateAttributes(target) {
  listeners.forEach(({ selector, Component }) => {
    try {
      if (selector.toLowerCase() === target.nodeName.toLowerCase()) {
        renderComponent(target, Component);
      }
      /* eslint-disable no-empty */
    } catch (e) {}
  });
}

const observer = new MutationObserver(mutationsList => {
  for (const mutation of mutationsList) {
    if (mutation.type == 'childList') {
      if (mutation.removedNodes.length > 0) {
        mutation.removedNodes.forEach(checkRemoved);
      }

      if (mutation.addedNodes.length > 0) {
        mutation.addedNodes.forEach(checkAdded);
      }
    } else if (mutation.type == 'attributes') {
      updateAttributes(mutation.target);
    }
  }
});

observer.observe(document, {
  childList: true,
  subtree: true,
  attributes: true,
});

export default (selector, Component) => {
  listeners.push({
    selector,
    Component,
  });

  try {
    const nodes = document.querySelectorAll(selector);
    Array.prototype.forEach.call(nodes, node => {
      renderComponent(node, Component);
    });
    /* eslint-disable no-empty */
  } catch (e) {}
};
