import { useRef, useState, useEffect } from 'react';

const EASE_IN = 'cubic-bezier(0.895, 0.03, 0.685, 0.22)';
const EASE_OUT = 'cubic-bezier(0.165, 0.84, 0.44, 1)';

export default (node, dir = 'left', duration = 400) => {
  const animation = useRef();
  const [state, setState] = useState('closed');

  useEffect(() => {
    const transforms =
      dir === 'left'
        ? [{ transform: 'translateX(-100%)' }, { transform: 'translateX(0%)' }]
        : [{ transform: 'translateX(100%)' }, { transform: 'translateX(0%)' }];

    animation.current = node.animate(transforms, {
      fill: 'forwards',
      easing: EASE_OUT,
      duration,
    });
    animation.current.currentTime = 0;
    animation.current.pause();

    return () => {
      animation.current.pause();
      animation.current.cancel();
    };
  }, [node, dir, duration]);

  function open() {
    if (animation.current) {
      setState('opening');

      animation.current.playbackRate = 1;
      animation.current.addEventListener(
        'finish',
        () => {
          setState('open');
        },
        { once: true }
      );

      animation.current.effect.updateTiming({
        easing: EASE_OUT,
        duration,
      });

      animation.current.play();
    }
  }

  function close() {
    setState('closing');
    animation.current.addEventListener(
      'finish',
      () => {
        animation.current.pause();
        animation.current.playbackRate = 1;
        animation.current.currentTime = 0;
        setState('closed');
      },
      { once: true }
    );

    animation.current.effect.updateTiming({
      easing: EASE_IN,
    });

    animation.current.playbackRate = -1;
    animation.current.play();
  }

  return {
    state,
    open,
    close,
  };
};
