/*
 * This file is part of the Det Norske Teatret Nettside 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { useCookies } from 'react-cookie';
import ExitIcon from 'components/Icons/Exit/Exit';
import './CookieConsent.scss';

/**
 * This is the CookieConsent component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function CookieConsent({ name, text, link, close, accept }) {
  const [cookies, setCookie] = useCookies([name]);
  const [visible, setVisible] = useState(false);
  const cookie = cookies[name] ?? false;

  useEffect(() => {
    setVisible(cookie !== 'true');
  }, [cookie]);

  function onAccept() {
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1);

    setCookie(name, true, {
      path: '/',
      expires,
    });
  }

  function renderMoreLink() {
    if (!link) return null;

    const { text, ...props } = link;
    return (
      <>
        {' '}
        <a {...props}>{text}</a>
      </>
    );
  }

  return (
    <CSSTransition in={visible} timeout={900} unmountOnExit>
      <aside className="cookie-consent">
        <div>
          <div className="close">
            <button onClick={onAccept}>
              <ExitIcon title={close} />
            </button>
          </div>
          <p>
            {text}
            {renderMoreLink()}
          </p>
          <button className="accept" onClick={onAccept}>
            {accept}
          </button>
        </div>
      </aside>
    </CSSTransition>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
CookieConsent.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
  }),
  close: PropTypes.string.isRequired,
  accept: PropTypes.string.isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
CookieConsent.defaultProps = {
  name: 'cookieConsent',
  close: 'Close',
  accept: 'Accept',
};

export default CookieConsent;
