/*
 * This file is part of the Energima Nettside 2021 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';
import Arrow from 'components/Icons/Arrow/Arrow';
import scrollTo from 'utils/scrollTo';
import './Hint.scss';

/**
 * This is the Hint component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
export function Hint({ title, children, target: targetSelector, dir }) {
  function onClick() {
    const target = document.querySelector(targetSelector);
    if (target) {
      scrollTo(target);
    }
  }

  function renderText() {
    if (!children) return null;

    return <span className="text">{children}</span>;
  }

  return (
    <button className="hint" type="button" title={title} onClick={onClick}>
      <div>
        <Arrow down={dir === 'down'} up={dir === 'up'} title={children} />
        {renderText()}
      </div>
    </button>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Hint.propTypes = {
  target: PropTypes.string.isRequired,
  children: PropTypes.node,
  title: PropTypes.string,
  dir: PropTypes.oneOf(['up', 'down']).isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Hint.defaultProps = {
  target: 'body',
  dir: 'down',
  title: '',
  children: null,
};

function normalizeChildren(WrappedComponent) {
  return ({ children, ...props }) => {
    let normalized = '';

    if (children instanceof DocumentFragment) {
      normalized = children.textContent;
    }

    return <WrappedComponent {...props} children={normalized} />;
  };
}

export default normalizeChildren(Hint);
