/*
 * This file is part of the Energima Nettside 2021 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Magnifier from 'components/Icons/Magnifier/Magnifier';
import Select from './Select/Select';

/**
 * This is the Filter component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function Filter({ companies, services, state, labels, onChange }) {
  const { management, search, company, service } = state;

  return (
    <div className="filter">
      <div className="management" appear="true">
        <div className="label">{labels.management.display}</div>
        <button
          className={classnames('all', {
            active: !management & !company & !service & !search,
          })}
          onClick={() => {
            onChange({
              management: false,
              company: '',
              service: '',
              search: '',
            });
          }}
        >
          <span>{labels.management.all}</span>
        </button>
        <button
          className={classnames('filtered', { active: management })}
          onClick={() => {
            onChange({ management: true });
          }}
        >
          <span>{labels.management.title}</span>
        </button>
      </div>
      <div className="field-row field-search" appear="true">
        <label htmlFor="employee-search" className="sr-only">
          {labels.search}
        </label>
        <Magnifier />
        <input
          id="employee-search"
          placeholder={labels.search}
          value={search}
          onChange={({ target: { value } }) => {
            onChange({ search: value });
          }}
        />
      </div>
      <Select
        name="service"
        labels={labels.service}
        options={services.map(({ slug, name, icon }) => ({
          label: name,
          value: slug,
          icon,
        }))}
        value={service}
        onChange={onChange}
      />
      <Select
        name="company"
        labels={labels.company}
        options={companies.map(({ slug, name }) => ({
          label: name,
          value: slug,
        }))}
        value={company}
        onChange={onChange}
      />
    </div>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Filter.propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  state: PropTypes.shape({
    search: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    service: PropTypes.string.isRequired,
  }).isRequired,
  labels: PropTypes.shape({
    search: PropTypes.string.isRequired,
    management: PropTypes.shape({
      display: PropTypes.string.isRequired,
      all: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
    company: PropTypes.shape({
      label: PropTypes.string.isRequired,
      select: PropTypes.string.isRequired,
    }).isRequired,
    service: PropTypes.shape({
      label: PropTypes.string.isRequired,
      select: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Filter.defaultProps = {
  companies: [],
  services: [],
  state: {
    search: '',
    company: '',
    service: '',
  },
  labels: {
    management: {
      display: 'Display:',
      all: 'All',
      title: 'Management and administration',
    },
    search: 'Search',
    company: {
      label: 'Company',
      select: 'Select company',
    },
    service: {
      label: 'Service',
      select: 'Select service',
    },
  },
  onChange: () => {},
};

export default Filter;
