/*
 * This file is part of the Akershus Eiendom Nettsted 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Arrow.scss';

/**
 * This is the Arrow component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */

function Arrow({ className, up, down, right, left, title }) {
  function renderSvg() {
    if (up || down) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16.828"
          height="16"
          viewBox="0 0 16.828 16"
        >
          <title>{title}</title>
          <g transform="translate(15.414 1) rotate(90)">
            <path className="a" d="M0,0H14" transform="translate(0 7)" />
            <path className="a" d="M0,14,7,7,0,0" transform="translate(7)" />
          </g>
        </svg>
      );
    }

    if (left || right) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36.58"
          height="38.237"
          viewBox="0 0 36.58 38.237"
        >
          <title>{title}</title>
          <g className="a" transform="translate(2 35.409) rotate(-90)">
            <g transform="translate(0 32.58) rotate(-90)">
              <path
                className="b"
                d="M0,0H32.58"
                transform="translate(0 16.29)"
              />
              <path
                className="b"
                d="M0,0,16.29,16.29,0,32.58"
                transform="translate(16.29)"
              />
            </g>
          </g>
        </svg>
      );
    }

    return null;
  }

  return (
    <span
      className={classnames(
        'svg',
        'svg-arrow',
        {
          'svg-arrow-up': up,
          'svg-arrow-down': down,
          'svg-arrow-right': right,
          'svg-arrow-left': left,
        },
        className
      )}
    >
      <span>{renderSvg()}</span>
    </span>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Arrow.propTypes = {
  className: PropTypes.string,
  up: PropTypes.bool,
  down: PropTypes.bool,
  right: PropTypes.bool,
  left: PropTypes.bool,
  title: PropTypes.string,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Arrow.defaultProps = {
  up: false,
  down: false,
  right: false,
  left: false,
  title: 'Arrow',
};

export default Arrow;
