/*
 * This file is part of the Energima Nettside 2021 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import addSwipeEvent from 'swipe-listener/dist/swipe-listener.min';
import useMenu from 'hooks/menu';
import useSlide from 'hooks/slide';
import Magnifier from 'components/Icons/Magnifier/Magnifier';
import Exit from 'components/Icons/Exit/Exit';
import User from 'components/Icons/User/User';
import Logo from 'components/Icons/Logo/Logo';
import Hamburger from 'components/Icons/Hamburger/Hamburger';

const tray = document.getElementById('menu');

/**
 * This is the Menu component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function Menu(
  {
    home,
    search,
    main,
    sub,
    labels,
    enabled,
    onFocus,
    onOpen,
    onUser,
    onState,
  },
  ref
) {
  const [buttonNode, submenuNode, open, setOpen] = useMenu('menu', enabled);
  const { state, ...slide } = useSlide(tray, 'right');

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
    },
    close: () => {
      setOpen(false);
    },
  }));

  useEffect(() => {
    if (open) {
      onOpen();
      slide.open();
    }

    return () => {
      if (open) {
        slide.close();
      }
    };
  }, [open]);

  useEffect(() => {
    onState(state);
  }, [state]);

  useEffect(() => {
    let listener = null;

    function onSwipe(e) {
      if (e.detail.directions.right) {
        slide.close();
        setOpen(false);
      }
    }

    if (open && tray) {
      listener = addSwipeEvent(tray);
      tray.addEventListener('swipe', onSwipe);
    }

    return () => {
      if (tray) {
        tray.removeEventListener('swipe', onSwipe);
        if (listener) {
          listener.off();
        }
      }
    };
  }, [open]);

  function renderNode({ id, text, url, active, newWindow }) {
    if (newWindow) {
      return (
        <div key={id}>
          <a
            className={classnames({ active })}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            role="menuitem"
          >
            {text}
          </a>
        </div>
      );
    }

    return (
      <div key={id}>
        <a className={classnames({ active })} href={url} role="menuitem">
          {text}
        </a>
      </div>
    );
  }

  function renderContent() {
    if (!open && state === 'open') {
      return null;
    }

    if (!open && state === 'closed') {
      return null;
    }

    const content = (
      <div ref={submenuNode}>
        <div className="overlay-top top">
          <button className="user" onClick={onUser}>
            <User title={labels.user} />
          </button>
          <a className="home" href={home.url}>
            <Logo title={home.text} />
          </a>
          <button
            className="close"
            role="menuitem"
            onClick={() => {
              setOpen(false);
            }}
          >
            <Exit title={labels.close} />
          </button>
        </div>
        <div className="main">{main.map(renderNode)}</div>
        <div className="sub">{sub.map(renderNode)}</div>
        <form className="search" action={search.url} method="get">
          <div className="field">
            <label htmlFor="menu-search">{search.text}</label>
            <div className="input">
              <input
                id="menu-search"
                type="text"
                name="f"
                placeholder={search.text}
              />
              <button type="submit">
                <Magnifier title={search.text} />
              </button>
            </div>
          </div>
        </form>
      </div>
    );

    if (tray) {
      return createPortal(content, tray);
    }

    return content;
  }

  return (
    <>
      <button
        ref={buttonNode}
        className="menu-toggle"
        aria-label={open ? labels.close : labels.open}
        aria-haspopup="true"
        aria-controls="menu"
        aria-expanded={open ? 'true' : 'false'}
        onFocus={onFocus}
        onClick={() => {
          setOpen(true);
        }}
      >
        <span>
          <span className="text">{labels.open}</span>
          <Hamburger />
        </span>
      </button>
      {renderContent()}
    </>
  );
}

Menu = forwardRef(Menu);

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Menu.propTypes = {
  enabled: PropTypes.bool,
  name: PropTypes.string,
  home: PropTypes.shape({
    url: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  search: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  main: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      active: PropTypes.bool,
      newWindow: PropTypes.bool,
    })
  ).isRequired,
  sub: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      active: PropTypes.bool,
      newWindow: PropTypes.bool,
    })
  ).isRequired,
  labels: PropTypes.shape({
    open: PropTypes.string.isRequired,
    close: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
  }).isRequired,
  onFocus: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onUser: PropTypes.func.isRequired,
  onState: PropTypes.func.isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Menu.defaultProps = {
  enabled: false,
  name: 'energima',
  home: {
    url: '/',
    text: 'Home',
  },
  main: [],
  sub: [],
  search: {
    text: 'Search',
    url: '/search',
  },
  labels: {
    open: 'Menu',
    close: 'Close',
    user: 'User',
  },
  onFocus: () => {},
  onOpen: () => {},
  onUser: () => {},
  onState: () => {},
};

export default Menu;
