import { useState, useEffect } from 'react';
export default (ref, settings = {}) => {
  const [inview, setInview] = useState(false);

  const { root, rootMargin, threshold } = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0,
    ...settings,
  };

  const [observer, setObserver] = useState(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(({ target, isIntersecting }) => {
          if (target === ref.current) {
            setInview(isIntersecting);
          }
        });
      },
      {
        root,
        rootMargin,
        threshold,
      }
    );

    setObserver(observer);

    return function cleanup() {
      observer.disconnect();
      setObserver(null);
    };
  }, [root, rootMargin, threshold, ref]);

  useEffect(() => {
    const { current } = ref;
    if (observer) {
      if (current && observer) {
        observer.observe(current);
      }
    }
    return function cleanup() {
      if (observer) {
        if (current && observer) {
          observer.unobserve(current);
        }
      }
    };
  }, [ref, observer]);

  return inview;
};
