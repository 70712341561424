import { useRef, useState, useEffect } from 'react';

export default (ref, enabled = true, options = {}) => {
  const { delay, ...settings } = {
    delay: 0,
    duration: 400,
    ...options,
  };
  const animation = useRef(null);
  const [played, setPlayed] = useState(false);

  useEffect(() => {
    if (!enabled) {
      setPlayed(true);
    }
  }, [enabled]);

  useEffect(() => {
    function onFinished() {
      setPlayed(true);
    }

    if (!played) {
      animation.current = ref.current.animate(
        [
          {
            transform: 'translateY(20px)',
            opacity: 0,
          },
          {
            transform: 'translateY(0px)',
            opacity: 1,
          },
        ],
        400
      );
      animation.current.addEventListener('finish', onFinished);
      animation.current.pause();
    }

    return () => {
      if (animation.current) {
        animation.current.removeEventListener('finish', onFinished);
        animation.current.cancel();
        animation.current = null;
      }
    };
  }, [played]);

  useEffect(() => {
    if (animation.current) {
      animation.current.effect.updateTiming(settings);
    }
  }, [JSON.stringify(settings)]);

  function execute() {
    setTimeout(() => {
      if (animation.current) {
        animation.current.play();
      }
    }, delay);
  }

  return execute;
};
